<template>
  <div>
    <Breadcrumbs :curData="curName">
      <template slot="action-buttons">
        <v-btn
          v-show="isShow"
          class="pl-5 pr-5"
          depressed
          color="primary"
          @click="saveData()"
          >保存</v-btn
        >
      </template>
    </Breadcrumbs>
    <div class="main-wrapper">
      <TabList
        @tabEvent="tabEvent($event)"
        :tabList="tabList"
        :curTabId="curTabId"
      />

      <div class="tabitem-wrapper">
        <component :is="curCom" :customData="customData" ref="component" />
      </div>
    </div>
  </div>
</template>

<script>
import { api_request } from "@/util/network";
import Breadcrumbs from "@/components/Breadcrumbs";
import TabList from "@/components/TabList";
import CustomInfo from "./component/CustomInfo";
import PcCustom from "./component/PcCustom";
import MobileCustom from "./component/MobileCustom";
import bus from "@/util/eventBus";

export default {
  name: "CustomView",
  data() {
    return {
      curTabId: 0,
      curCom: "CustomInfo",
      tabList: [
        {
          id: 0,
          name: "基础信息"
        },
        {
          id: 1,
          name: "PC自定义"
        },
        {
          id: 2,
          name: "移动端自定义"
        }
      ],
      customData: {},
      curName: null,
      isShow: false
    };
  },
  created() {
    this.fetchAccountDetail(this.$route.params.id);
    let tabId = this.$route.query.tabId;
    if (tabId != null) {
      this.isShow = tabId != 0 ? true : false;
      this.curTabId = Number(tabId);
      this.curCom = ["CustomInfo", "PcCustom", "MobileCustom"][tabId];
    }

    bus.$off("refresh").$on("refresh", () => {
      this.fetchAccountDetail(this.$route.params.id);
    });
  },
  mounted() {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL); //这里有没有都无所谓，最好是有以防万一
      window.addEventListener("popstate", this.goBack, false); // 回退时执行goback方法
    }
  },
  methods: {
    saveData() {
      if (this.curTabId === 1) {
        this.$refs.component.modifySetLogin();
      } else if (this.curTabId === 2) {
        this.$refs.component.modifySetMLogin();
      }
    },
    tabEvent(id) {
      this.curTabId = id;
      this.isShow = id != 0 ? true : false;
      this.$router.push({
        path: `/customLogin/view/${this.$route.params.id}`,
        query: {
          tabId: id
        }
      });
      history.pushState(null, null, document.URL);
    },
    fetchAccountDetail(id) {
      return this.$http
        .get(`api/custom_login/${id}/describe`)
        .delegateTo(api_request)
        .then(data => {
          if (data) {
            data.url = `${window.location.origin}/logins/${data.external_id}.html`;
            this.customData = data;
            this.curName = data.name ? data.name : "";
          }
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    goBack() {
      this.$router.replace({ path: "/customLogin/list" });
    }
  },
  watch: {
    curTabId(v) {
      this.curCom = ["CustomInfo", "PcCustom", "MobileCustom"][v];
    }
  },
  components: {
    Breadcrumbs,
    TabList,
    CustomInfo,
    PcCustom,
    MobileCustom
  }
};
</script>

<style lang="less" scoped>
.datasource-container {
  width: 100%;
  box-sizing: border-box;
  .every-item {
    display: flex;
    height: 50px;
    align-items: flex-start;
    margin-top: 20px;
    label {
      flex: 0 0 140px;
      text-align: left;
      color: #666;
    }
    .input-item {
      input {
        width: 400px;
        height: 30px;
        box-sizing: border-box;
        padding-left: 20px;
        outline: none;
        border-bottom: 1px solid #949494;
        font-size: 16px;
        &.active {
          border-bottom: none;
        }
      }

      .prompt {
        font-size: 12px;
        color: #666;
      }
    }
  }

  .submit-wrapper {
    width: 40%;
    text-align: center;
    margin-top: 50px;
    .submitbtn {
      width: 120px;
      height: 40px;
      line-height: 40px;
    }
  }
}
</style>

<style lang="less" scoped>
.main-wrapper {
  width: 100%;
  box-sizing: border-box;
  padding: 0 27px;
  margin-bottom: 30px;
}
</style>
