<template>
  <div class="datasource-container">
    <LoginSetting
      device_type="PC"
      ref="LoginSetting"
      :customData="customData"
    ></LoginSetting>
    <ConfirmBox ref="confirm" />
  </div>
</template>

<script>
import { api_request } from "@/util/network";
import bus from "@/util/eventBus";
import ConfirmBox from "@/components/ConfirmBox";
import LoginSetting from "../editor/LoginSetting";

const EDITORS = {};

export default {
  name: "PcCustom",
  props: ["customData"],
  data() {
    return {
      sheet_editor: null,
      drawer: false,
      externals: [],
      subLists: [],
      accountId: null,
      curSubAccount: null,
      is_readonly: null
    };
  },
  mounted() {
    this.getCurIdData();
    this.getCurSubList();
  },
  methods: {
    modifySetLogin() {
      this.$refs.LoginSetting.modifyPreLoginStyle();
    },
    getCurIdData() {
      if (
        this.customData &&
        this.customData.externals &&
        this.customData.externals.length > 0
      ) {
        let { externals, id } = this.customData;
        this.externals = externals;
        this.accountId = id;
      }
    },
    //external_id ===》external数组中的id
    unbind(external) {
      this.$refs.confirm
        .showConfirm({
          contentText: `您确认要解绑？ #${external.id} ${external.name}`,
          callback: () => {
            return this.$http
              .post(`api/customData/${this.accountId}/unbind`, {
                external_id: external.id
              })
              .delegateTo(api_request)
              .then(() => {
                return "解绑成功";
              })
              .catch(({ code, message }) => {
                throw `解绑成功：${this.$t("api." + code)}, 额外信息: ${this.$t(
                  "api." + typeof message === "string"
                    ? message
                    : JSON.stringify(message)
                )}`;
              });
          }
        })
        .catch(() => {
          return;
        });
    },
    edit(item) {
      this.is_readonly = null;
      this.curSubAccount = item;
      this.sheet_editor = EDITORS["EditSubApplication"];
      setTimeout(() => this.$store.commit("show_sheet", { show: true }), 1);
    },
    show_sheet() {
      this.sheet_editor = EDITORS["CreateSubApplication"];
      setTimeout(() => this.$store.commit("show_sheet", { show: true }), 1);
    },
    editAccount() {
      this.sheet_editor = EDITORS["customData"];
      setTimeout(() => this.$store.commit("show_sheet", { show: true }), 1);
    },
    editDepartment() {
      this.sheet_editor = EDITORS["DEPARTMENT"];
      setTimeout(() => this.$store.commit("show_sheet", { show: true }), 1);
    },

    // 子账号应用列表
    getCurSubList() {
      this.subLists = this.customData.sub_accounts;
    },
    deleteSubItem({ application_id, id, claims: { username } }) {
      this.$refs.confirm
        .showConfirm({
          contentText: `您确认要删除子账户？ #${id} ${username}`,
          callback: () => {
            return this.$http
              .post(
                `api/application/${application_id}/sub_account/${id}/archive`
              )
              .delegateTo(api_request)
              .then(() => {
                bus.$emit("refresh");
                return "子账户已成功删除";
              })
              .catch(({ code, message }) => {
                throw `子账户删除失败：${this.$t(
                  "api." + code
                )}, 额外信息: ${this.$t("api." + JSON.stringify(message))}`;
              });
          }
        })
        .catch(() => {
          return;
        });
    }
  },
  watch: {
    customData() {
      this.getCurIdData();
      this.getCurSubList();
    }
  },
  components: {
    ConfirmBox,
    LoginSetting
  }
};
</script>

<style lang="less" scoped>
.datasource-container {
  width: 100%;
  box-sizing: border-box;
  .every-item {
    display: flex;
    height: 50px;
    align-items: flex-start;
    margin-top: 20px;
    label {
      flex: 0 0 140px;
      text-align: left;
      color: #666;
    }
    .input-item {
      input {
        width: 400px;
        height: 30px;
        box-sizing: border-box;
        padding-left: 20px;
        outline: none;
        border-bottom: 1px solid #949494;
        font-size: 16px;
        &.active {
          border-bottom: none;
        }
      }

      .prompt {
        font-size: 12px;
        color: #666;
      }
    }
  }

  .submit-wrapper {
    width: 40%;
    text-align: center;
    margin-top: 50px;
    .submitbtn {
      width: 120px;
      height: 40px;
      line-height: 40px;
    }
  }
}

/deep/.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 0 0px;
}
</style>
