<template>
  <div class="datasource-container">
    <MLoginSetting
      ref="MLoginSetting"
      device_type="MOBILE"
      :customData="customData"
    ></MLoginSetting>
    <ConfirmBox ref="confirm" />
  </div>
</template>

<script>
import { api_request } from "@/util/network";
import bus from "@/util/eventBus";
import MLoginSetting from "../editor/MLoginSetting";
import ConfirmBox from "@/components/ConfirmBox";

export default {
  name: "MobileCustom",
  props: ["customData"],
  data() {
    return {
      drawer_status: [false, false],
      current_editor: null,
      current_edit_type: "create",
      sheet_editor: null,
      departments: [],
      groups: []
    };
  },
  created() {
    this.getCurIdData();
  },
  methods: {
    modifySetMLogin() {
      this.$refs.MLoginSetting.modifyMLoginStyle();
    },
    getCurIdData() {
      if (this.customData.departments && this.customData.groups) {
        let { departments, groups } = this.customData;
        this.departments = departments;
        this.groups = groups;
      }
    },
    archiveAccount(group) {
      this.$refs.confirm
        .showConfirm({
          contentText: `您确认要删除？ #${group.id} ${group.name}`,
          callback: () => {
            return this.$http
              .post(`api/customData/${group.id}/archive`)
              .delegateTo(api_request)
              .then(() => {
                bus.$emit("refresh");
                return "删除成功";
              })
              .catch(({ code, message }) => {
                throw `删除失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
                  "api." + JSON.stringify(message)
                )}`;
              });
          }
        })
        .catch(() => {
          return;
        });
    }
  },
  watch: {
    customData() {
      this.getCurIdData();
    }
  },
  components: {
    ConfirmBox,
    MLoginSetting
  }
};
</script>

<style lang="less" scoped>
.datasource-container {
  width: 100%;
  box-sizing: border-box;
  .every-item {
    display: flex;
    height: 50px;
    align-items: flex-start;
    margin-top: 20px;
    label {
      flex: 0 0 140px;
      text-align: left;
      color: #666;
    }
    .input-item {
      input {
        width: 400px;
        height: 30px;
        box-sizing: border-box;
        padding-left: 20px;
        outline: none;
        border-bottom: 1px solid #949494;
        font-size: 16px;
        &.active {
          border-bottom: none;
        }
      }

      .prompt {
        font-size: 12px;
        color: #666;
      }
    }
  }

  .submit-wrapper {
    width: 40%;
    text-align: center;
    margin-top: 50px;
    .submitbtn {
      width: 120px;
      height: 40px;
      line-height: 40px;
    }
  }
}

/deep/.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 0 0px;
}
</style>
