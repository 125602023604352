<template>
  <div class="datasource-container">
    <v-card elevation="0" class="dense">
      <v-card-title
        >基础信息
        <a @click.stop="show_sheet('edit', 'CustomEditor')"
          >(修改)</a
        ></v-card-title
      >
      <v-card-text>
        <v-row>
          <v-col>
            <span>名称：</span>
            <span
              :style="{
                color: customData.name ? '' : '#9195a3'
              }"
            >
              {{ customData.name || "未填写" }}
            </span>
          </v-col>
          <v-col>
            <span>自定义登录ID：</span>
            <span
              :style="{
                color: customData.external_id ? '' : '#9195a3'
              }"
            >
              {{ customData.external_id || "未填写" }}
            </span>
          </v-col>
          <v-col>
            <span>自定义登录地址：</span>
            <span
              :style="{
                color: customData.url ? '' : '#9195a3'
              }"
            >
              {{ customData.url || "未填写" }}
            </span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <Component
      :is="sheet_editor"
      :show="drawer_status"
      :is_modify="current_edit_type === 'edit'"
      type="edit"
      :entity_info="customData"
      :editeFilterExtrl="editeFilterExtrl"
      v-if="customData"
    />
  </div>
</template>

<script>
import CustomEditor from "../editor/CustomEditor";

const EDITORS = {
  CustomEditor: CustomEditor
};

export default {
  name: "CustomInfo",
  props: ["customData", "filterExtrl", "editeFilterExtrl"],
  data() {
    return {
      attributes: {},
      // drawer
      current_editor: null,
      current_edit_type: "create",
      drawer_status: [false, false],
      sheet_editor: null
    };
  },
  methods: {
    show_sheet(edit_type, type) {
      this.sheet_editor = EDITORS[type];
      this.current_edit_type = edit_type;
      setTimeout(() => {
        this.$set(this.drawer_status, 0, true);
      }, 1);
    }
  },
  components: {
    CustomEditor
  }
};
</script>

<style lang="less" scoped>
.datasource-container {
  width: 100%;
  box-sizing: border-box;
  .every-item {
    display: flex;
    height: 50px;
    align-items: flex-start;
    margin-top: 20px;
    label {
      flex: 0 0 140px;
      text-align: left;
      color: #666;
    }
    .input-item {
      input {
        width: 400px;
        height: 30px;
        box-sizing: border-box;
        padding-left: 20px;
        outline: none;
        border-bottom: 1px solid #949494;
        font-size: 16px;
        &.active {
          border-bottom: none;
        }
      }

      .prompt {
        font-size: 12px;
        color: #666;
      }
    }
  }

  .submit-wrapper {
    width: 40%;
    text-align: center;
    margin-top: 50px;
    .submitbtn {
      width: 120px;
      height: 40px;
      line-height: 40px;
    }
  }
}
</style>
